// @ts-nocheck
import type { ODataModelResponseV4, ODataCollectionResponseV4 } from "@odata2ts/odata-core";
import type { StringCollection } from "@odata2ts/odata-query-objects";
import { QStringCollection, QGuidCollection } from "@odata2ts/odata-query-objects";
import type { ODataHttpClient, ODataHttpClientConfig, HttpResponseModel } from "@odata2ts/http-client-api";
import { ODataService, EntityTypeServiceV4, ODataServiceOptionsInternal, CollectionServiceV4, EntitySetServiceV4 } from "@odata2ts/odata-service";
import type { ModelId, HealthCheckSettingId, ManufacturerId, LogEntryId, FeedId, TenantId, OsId, Model, EditableModel, HealthCheckSetting, EditableHealthCheckSetting, HealthCheckValueSetting, EditableHealthCheckValueSetting, HealthCheckCountSetting, EditableHealthCheckCountSetting, Manufacturer, EditableManufacturer, LogEntry, EditableLogEntry, TenantLogEntry, EditableTenantLogEntry, FeedLogEntry, EditableFeedLogEntry, Feed, EditableFeed, Tenant, EditableTenant, HcIgnore, EditableHcIgnore, PolicyBaseId, ActionResult, Tenant_AdminConsentParams, Tenant_ConsentParams, LookupPolicy, RefreshedWrappedArray_1OfDeviceReportEntry, Tenant_ReportParams, Tenant_ConsentsParams, Os, EditableOs, OsBuild, EditableOsBuild, PolicyBase, EditablePolicyBase, Policy, EditablePolicy, WindowsPolicy, EditableWindowsPolicy, AndroidPolicy, EditableAndroidPolicy, DeadlinePolicy, EditableDeadlinePolicy, RefreshCycle, EditableRefreshCycle } from "./JiscCloudAssistModelsModel";
import type { QModel, QHealthCheckSetting, QHealthCheckValueSetting, QHealthCheckCountSetting, QManufacturer, QLogEntry, QTenantLogEntry, QFeedLogEntry, QFeed, QTenant, QHcIgnore, QOs, QOsBuild, QPolicyBase, QPolicy, QWindowsPolicy, QAndroidPolicy, QDeadlinePolicy, QRefreshCycle } from "./QJiscCloudAssistModels";
import { QModelId, QHealthCheckSettingId, QManufacturerId, QLogEntryId, QFeedId, QTenantId, QOsId, qModel, qHealthCheckSetting, qHealthCheckValueSetting, qHealthCheckCountSetting, qManufacturer, qLogEntry, qTenantLogEntry, qFeedLogEntry, qFeed, Feed_QRefresh, qTenant, qHcIgnore, QPolicyBaseId, Tenant_QAdminConsent, Tenant_QConsent, Tenant_QLookupPolicy, Tenant_QRefresh, Tenant_QReport, Tenant_QConsents, qOs, qOsBuild, qPolicyBase, qPolicy, qWindowsPolicy, qAndroidPolicy, qDeadlinePolicy, qRefreshCycle } from "./QJiscCloudAssistModels";

export class JiscCloudAssistModelsService<in out ClientType extends ODataHttpClient> extends ODataService<ClientType> {
    public models(): ModelCollectionService<ClientType>;
    public models(id: ModelId): ModelService<ClientType>;
    public models(id?: ModelId | undefined) {
        const fieldName = "Models";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new ModelCollectionService(client, path, fieldName, options)
        : new ModelService(client, path, new QModelId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public healthChecks(): HealthCheckSettingCollectionService<ClientType>;
    public healthChecks(id: HealthCheckSettingId): HealthCheckSettingService<ClientType>;
    public healthChecks(id?: HealthCheckSettingId | undefined) {
        const fieldName = "HealthChecks";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new HealthCheckSettingCollectionService(client, path, fieldName, options)
        : new HealthCheckSettingService(client, path, new QHealthCheckSettingId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public manufacturers(): ManufacturerCollectionService<ClientType>;
    public manufacturers(id: ManufacturerId): ManufacturerService<ClientType>;
    public manufacturers(id?: ManufacturerId | undefined) {
        const fieldName = "Manufacturers";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new ManufacturerCollectionService(client, path, fieldName, options)
        : new ManufacturerService(client, path, new QManufacturerId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public tenantLogs(): TenantLogEntryCollectionService<ClientType>;
    public tenantLogs(id: LogEntryId): TenantLogEntryService<ClientType>;
    public tenantLogs(id?: LogEntryId | undefined) {
        const fieldName = "TenantLogs";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new TenantLogEntryCollectionService(client, path, fieldName, options)
        : new TenantLogEntryService(client, path, new QLogEntryId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public feedLogs(): FeedLogEntryCollectionService<ClientType>;
    public feedLogs(id: LogEntryId): FeedLogEntryService<ClientType>;
    public feedLogs(id?: LogEntryId | undefined) {
        const fieldName = "FeedLogs";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new FeedLogEntryCollectionService(client, path, fieldName, options)
        : new FeedLogEntryService(client, path, new QLogEntryId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public feeds(): FeedCollectionService<ClientType>;
    public feeds(id: FeedId): FeedService<ClientType>;
    public feeds(id?: FeedId | undefined) {
        const fieldName = "Feeds";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new FeedCollectionService(client, path, fieldName, options)
        : new FeedService(client, path, new QFeedId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public tenants(): TenantCollectionService<ClientType>;
    public tenants(id: TenantId): TenantService<ClientType>;
    public tenants(id?: TenantId | undefined) {
        const fieldName = "Tenants";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new TenantCollectionService(client, path, fieldName, options)
        : new TenantService(client, path, new QTenantId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public os(): OsCollectionService<ClientType>;
    public os(id: OsId): OsService<ClientType>;
    public os(id?: OsId | undefined) {
        const fieldName = "OS";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new OsCollectionService(client, path, fieldName, options)
        : new OsService(client, path, new QOsId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }
}

export class ModelService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Model, EditableModel, QModel> {
    private _aliases?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qModel, options);
    }

    public aliases() {
        if(!this._aliases) {
          const { client, path, options } = this.__base;
          this._aliases = new CollectionServiceV4(client, path, "aliases", new QStringCollection(), options)
        }

        return this._aliases
    }
}

export class ModelCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Model, EditableModel, QModel, ModelId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qModel, new QModelId(name), options);
    }
}

export class HealthCheckSettingService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, HealthCheckSetting, EditableHealthCheckSetting, QHealthCheckSetting> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckSetting, options);
    }

    public asHealthCheckValueSettingService() {
        const { client, path, options } = this.__base;
        return new HealthCheckValueSettingService(client, path, "Jisc.Cloud.Assist.Models.HealthCheckValueSetting", { ...options, subtype: true });
    }

    public asHealthCheckCountSettingService() {
        const { client, path, options } = this.__base;
        return new HealthCheckCountSettingService(client, path, "Jisc.Cloud.Assist.Models.HealthCheckCountSetting", { ...options, subtype: true });
    }
}

export class HealthCheckSettingCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, HealthCheckSetting, EditableHealthCheckSetting, QHealthCheckSetting, HealthCheckSettingId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckSetting, new QHealthCheckSettingId(name), options);
    }

    public asHealthCheckValueSettingCollectionService() {
        const { client, path, options } = this.__base;
        return new HealthCheckValueSettingCollectionService(client, path, "Jisc.Cloud.Assist.Models.HealthCheckValueSetting", { ...options, subtype: true });
    }

    public asHealthCheckCountSettingCollectionService() {
        const { client, path, options } = this.__base;
        return new HealthCheckCountSettingCollectionService(client, path, "Jisc.Cloud.Assist.Models.HealthCheckCountSetting", { ...options, subtype: true });
    }
}

export class HealthCheckValueSettingService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, HealthCheckValueSetting, EditableHealthCheckValueSetting, QHealthCheckValueSetting> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckValueSetting, options);
    }
}

export class HealthCheckValueSettingCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, HealthCheckValueSetting, EditableHealthCheckValueSetting, QHealthCheckValueSetting, HealthCheckSettingId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckValueSetting, new QHealthCheckSettingId(name), options);
    }
}

export class HealthCheckCountSettingService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, HealthCheckCountSetting, EditableHealthCheckCountSetting, QHealthCheckCountSetting> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckCountSetting, options);
    }
}

export class HealthCheckCountSettingCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, HealthCheckCountSetting, EditableHealthCheckCountSetting, QHealthCheckCountSetting, HealthCheckSettingId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHealthCheckCountSetting, new QHealthCheckSettingId(name), options);
    }
}

export class ManufacturerService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Manufacturer, EditableManufacturer, QManufacturer> {
    private _aliases?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qManufacturer, options);
    }

    public aliases() {
        if(!this._aliases) {
          const { client, path, options } = this.__base;
          this._aliases = new CollectionServiceV4(client, path, "aliases", new QStringCollection(), options)
        }

        return this._aliases
    }
}

export class ManufacturerCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Manufacturer, EditableManufacturer, QManufacturer, ManufacturerId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qManufacturer, new QManufacturerId(name), options);
    }
}

export class LogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, LogEntry, EditableLogEntry, QLogEntry> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qLogEntry, options);
    }

    public asTenantLogEntryService() {
        const { client, path, options } = this.__base;
        return new TenantLogEntryService(client, path, "Jisc.Cloud.Assist.Models.TenantLogEntry", { ...options, subtype: true });
    }

    public asFeedLogEntryService() {
        const { client, path, options } = this.__base;
        return new FeedLogEntryService(client, path, "Jisc.Cloud.Assist.Models.FeedLogEntry", { ...options, subtype: true });
    }
}

export class LogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, LogEntry, EditableLogEntry, QLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qLogEntry, new QLogEntryId(name), options);
    }

    public asTenantLogEntryCollectionService() {
        const { client, path, options } = this.__base;
        return new TenantLogEntryCollectionService(client, path, "Jisc.Cloud.Assist.Models.TenantLogEntry", { ...options, subtype: true });
    }

    public asFeedLogEntryCollectionService() {
        const { client, path, options } = this.__base;
        return new FeedLogEntryCollectionService(client, path, "Jisc.Cloud.Assist.Models.FeedLogEntry", { ...options, subtype: true });
    }
}

export class TenantLogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, TenantLogEntry, EditableTenantLogEntry, QTenantLogEntry> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qTenantLogEntry, options);
    }
}

export class TenantLogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, TenantLogEntry, EditableTenantLogEntry, QTenantLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qTenantLogEntry, new QLogEntryId(name), options);
    }
}

export class FeedLogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, FeedLogEntry, EditableFeedLogEntry, QFeedLogEntry> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qFeedLogEntry, options);
    }
}

export class FeedLogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, FeedLogEntry, EditableFeedLogEntry, QFeedLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qFeedLogEntry, new QLogEntryId(name), options);
    }
}

export class FeedService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Feed, EditableFeed, QFeed> {
    private _manufacturers?: CollectionServiceV4<ClientType, StringCollection, QGuidCollection>;
    private _schedule?: RefreshCycleService<ClientType>;
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;
    private _feedQRefresh?: Feed_QRefresh;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qFeed, options);
    }

    public manufacturers() {
        if(!this._manufacturers) {
          const { client, path, options } = this.__base;
          this._manufacturers = new CollectionServiceV4(client, path, "manufacturers", new QGuidCollection(), options)
        }

        return this._manufacturers
    }

    public schedule(): RefreshCycleService<ClientType> {
        if(!this._schedule) {
          const { client, path, options } = this.__base;
          this._schedule = new RefreshCycleService(client, path, "schedule", options)
        }

        return this._schedule
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }

    public async refresh(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
        if(!this._feedQRefresh) {
          this._feedQRefresh = new Feed_QRefresh()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._feedQRefresh.buildUrl());
        return client.post(url, {}, requestConfig, getDefaultHeaders());
        
    }
}

export class FeedCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Feed, EditableFeed, QFeed, FeedId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qFeed, new QFeedId(name), options);
    }
}

export class TenantService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Tenant, EditableTenant, QTenant> {
    private _updateSchedule?: RefreshCycleService<ClientType>;
    private _deltaSchedule?: RefreshCycleService<ClientType>;
    private _healthCheckIgnores?: CollectionServiceV4<ClientType, HcIgnore, QHcIgnore, EditableHcIgnore>;
    private _tenantQAdminConsent?: Tenant_QAdminConsent;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qTenant, options);
    }

    public updateSchedule(): RefreshCycleService<ClientType> {
        if(!this._updateSchedule) {
          const { client, path, options } = this.__base;
          this._updateSchedule = new RefreshCycleService(client, path, "updateSchedule", options)
        }

        return this._updateSchedule
    }

    public deltaSchedule(): RefreshCycleService<ClientType> {
        if(!this._deltaSchedule) {
          const { client, path, options } = this.__base;
          this._deltaSchedule = new RefreshCycleService(client, path, "deltaSchedule", options)
        }

        return this._deltaSchedule
    }

    public healthCheckIgnores(): CollectionServiceV4<ClientType, HcIgnore, QHcIgnore, EditableHcIgnore> {
        if(!this._healthCheckIgnores) {
          const { client, path, options } = this.__base;
          this._healthCheckIgnores = new CollectionServiceV4(client, path, "healthCheckIgnores", qHcIgnore, options)
        }

        return this._healthCheckIgnores
    }

    public policies(): PolicyBaseCollectionService<ClientType>;
    public policies(id: PolicyBaseId): PolicyBaseService<ClientType>;
    public policies(id?: PolicyBaseId | undefined) {
        const fieldName = "policies";
        const { client, path, options, isUrlNotEncoded } = this.__base;
        return typeof id === "undefined" || id === null
        ? new PolicyBaseCollectionService(client, path, fieldName, options)
        : new PolicyBaseService(client, path, new QPolicyBaseId(fieldName).buildUrl(id, isUrlNotEncoded()), options);
    }

    public async adminConsent(params: Tenant_AdminConsentParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<ActionResult>>> {
        if(!this._tenantQAdminConsent) {
          this._tenantQAdminConsent = new Tenant_QAdminConsent()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQAdminConsent.buildUrl(params, isUrlNotEncoded()));
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQAdminConsent.convertResponse(response);
    }
}

export class TenantCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Tenant, EditableTenant, QTenant, TenantId> {
    private _tenantQConsent?: Tenant_QConsent;
    private _tenantQLookupPolicy?: Tenant_QLookupPolicy;
    private _tenantQRefresh?: Tenant_QRefresh;
    private _tenantQReport?: Tenant_QReport;
    private _tenantQConsents?: Tenant_QConsents;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qTenant, new QTenantId(name), options);
    }

    public async consent(params: Tenant_ConsentParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<ActionResult>>> {
        if(!this._tenantQConsent) {
          this._tenantQConsent = new Tenant_QConsent()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQConsent.buildUrl(params, isUrlNotEncoded()));
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQConsent.convertResponse(response);
    }

    public async lookupPolicy(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataCollectionResponseV4<LookupPolicy>>> {
        if(!this._tenantQLookupPolicy) {
          this._tenantQLookupPolicy = new Tenant_QLookupPolicy()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQLookupPolicy.buildUrl(isUrlNotEncoded()));
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQLookupPolicy.convertResponse(response);
    }

    public async refresh(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
        if(!this._tenantQRefresh) {
          this._tenantQRefresh = new Tenant_QRefresh()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQRefresh.buildUrl());
        return client.post(url, {}, requestConfig, getDefaultHeaders());
        
    }

    public async report(params: Tenant_ReportParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<RefreshedWrappedArray_1OfDeviceReportEntry>>> {
        if(!this._tenantQReport) {
          this._tenantQReport = new Tenant_QReport()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQReport.buildUrl());
        const response = await  client.post(url, this._tenantQReport.convertUserParams(params), requestConfig, getDefaultHeaders());
        return this._tenantQReport.convertResponse(response);
    }

    public async consents(params: Tenant_ConsentsParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataCollectionResponseV4<string>>> {
        if(!this._tenantQConsents) {
          this._tenantQConsents = new Tenant_QConsents()
        }

        const { addFullPath, client, getDefaultHeaders, isUrlNotEncoded } = this.__base;
        const url = addFullPath(this._tenantQConsents.buildUrl());
        const response = await  client.post(url, this._tenantQConsents.convertUserParams(params), requestConfig, getDefaultHeaders());
        return this._tenantQConsents.convertResponse(response);
    }
}

export class OsService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Os, EditableOs, QOs> {
    private _supportedVersions?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;
    private _builds?: CollectionServiceV4<ClientType, OsBuild, QOsBuild, EditableOsBuild>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qOs, options);
    }

    public supportedVersions() {
        if(!this._supportedVersions) {
          const { client, path, options } = this.__base;
          this._supportedVersions = new CollectionServiceV4(client, path, "supportedVersions", new QStringCollection(), options)
        }

        return this._supportedVersions
    }

    public builds(): CollectionServiceV4<ClientType, OsBuild, QOsBuild, EditableOsBuild> {
        if(!this._builds) {
          const { client, path, options } = this.__base;
          this._builds = new CollectionServiceV4(client, path, "builds", qOsBuild, options)
        }

        return this._builds
    }
}

export class OsCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Os, EditableOs, QOs, OsId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qOs, new QOsId(name), options);
    }
}

export class PolicyBaseService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, PolicyBase, EditablePolicyBase, QPolicyBase> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qPolicyBase, options);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }

    public asPolicyService() {
        const { client, path, options } = this.__base;
        return new PolicyService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.Policy", { ...options, subtype: true });
    }

    public asWindowsPolicyService() {
        const { client, path, options } = this.__base;
        return new WindowsPolicyService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.WindowsPolicy", { ...options, subtype: true });
    }

    public asAndroidPolicyService() {
        const { client, path, options } = this.__base;
        return new AndroidPolicyService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.AndroidPolicy", { ...options, subtype: true });
    }

    public asDeadlinePolicyService() {
        const { client, path, options } = this.__base;
        return new DeadlinePolicyService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.DeadlinePolicy", { ...options, subtype: true });
    }
}

export class PolicyBaseCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, PolicyBase, EditablePolicyBase, QPolicyBase, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qPolicyBase, new QPolicyBaseId(name), options);
    }

    public asPolicyCollectionService() {
        const { client, path, options } = this.__base;
        return new PolicyCollectionService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.Policy", { ...options, subtype: true });
    }

    public asWindowsPolicyCollectionService() {
        const { client, path, options } = this.__base;
        return new WindowsPolicyCollectionService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.WindowsPolicy", { ...options, subtype: true });
    }

    public asAndroidPolicyCollectionService() {
        const { client, path, options } = this.__base;
        return new AndroidPolicyCollectionService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.AndroidPolicy", { ...options, subtype: true });
    }

    public asDeadlinePolicyCollectionService() {
        const { client, path, options } = this.__base;
        return new DeadlinePolicyCollectionService(client, path, "Jisc.Cloud.Assist.Models.TenantModels.DeadlinePolicy", { ...options, subtype: true });
    }
}

export class PolicyService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Policy, EditablePolicy, QPolicy> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qPolicy, options);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }
}

export class PolicyCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Policy, EditablePolicy, QPolicy, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qPolicy, new QPolicyBaseId(name), options);
    }
}

export class WindowsPolicyService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, WindowsPolicy, EditableWindowsPolicy, QWindowsPolicy> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qWindowsPolicy, options);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }
}

export class WindowsPolicyCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, WindowsPolicy, EditableWindowsPolicy, QWindowsPolicy, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qWindowsPolicy, new QPolicyBaseId(name), options);
    }
}

export class AndroidPolicyService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, AndroidPolicy, EditableAndroidPolicy, QAndroidPolicy> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qAndroidPolicy, options);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }
}

export class AndroidPolicyCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, AndroidPolicy, EditableAndroidPolicy, QAndroidPolicy, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qAndroidPolicy, new QPolicyBaseId(name), options);
    }
}

export class DeadlinePolicyService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, DeadlinePolicy, EditableDeadlinePolicy, QDeadlinePolicy> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qDeadlinePolicy, options);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path, options } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection(), options)
        }

        return this._ignores
    }
}

export class DeadlinePolicyCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, DeadlinePolicy, EditableDeadlinePolicy, QDeadlinePolicy, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qDeadlinePolicy, new QPolicyBaseId(name), options);
    }
}

export class RefreshCycleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, RefreshCycle, EditableRefreshCycle, QRefreshCycle> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qRefreshCycle, options);
    }
}

export class OsBuildService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, OsBuild, EditableOsBuild, QOsBuild> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qOsBuild, options);
    }
}

export class HcIgnoreService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, HcIgnore, EditableHcIgnore, QHcIgnore> {
    constructor(client: ClientType, basePath: string, name: string, options?: ODataServiceOptionsInternal) {
        super(client, basePath, name, qHcIgnore, options);
    }
}
