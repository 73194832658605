import {
    Badge, Body1Stronger, FluentProvider,
    ProgressBar, Toaster, useId
} from "@fluentui/react-components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Login, Providers, ThemeToggle } from "@microsoft/mgt-react";
import { lazy, ReactElement, useState, useTransition } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { JTool } from "./Components/JTool";
import JToolContext from "./Components/JToolContext";
import Nav from "./Components/Nav";
import useIsSignedIn from "./Hooks/useIsSignedIn";
import useTheme from "./Hooks/useTheme";
import { LogType } from "./Interfaces";
import HomePage from "./Pages/HomePage";
import logo from "./logo.svg";

const Tenants = lazy(() => import("./Pages/AutomationPage"));
const Logs = lazy(() => import("./Pages/LogsPage"));
const HealthCheck = lazy(() => import("./Pages/HealthCheckPage"));
const Reports = lazy(() => import("./Pages/ReportsPage"));
const Permissions = lazy(() => import("./Pages/PermissionsPage"));
const About = lazy(() => import("./Pages/AboutPage"));
const Database = lazy(() => import("./Pages/DatabasePage"));
const Consent = lazy(() => import("./Pages/ConsentPage"));

export interface IAppProps { appInsights?: ApplicationInsights }

function App(props: IAppProps): ReactElement {
    const [isPending] = useTransition();
    const toasterId = useId("toaster");
    const [isDark, theme] = useTheme();
    const { isSignedIn, isAdminTenant, tenant, loading, token } = useIsSignedIn(true, props.appInsights);
    const [collapseNav, setCollapseNav] = useState<boolean>(false);

    return (
        <FluentProvider theme={theme}>
            <JToolContext.Provider value={new JTool(tenant, Providers.client, token, toasterId)}>
                <div id="jtool" className={`App ${isDark ? "mgt-dark" : ""}${collapseNav ? " collapseNav" : ""}`}>
                    <div style={{ display: "none", visibility: "hidden" }}><ThemeToggle darkModeActive={isDark} /></div>
                    <header id="header">
                        <div className="App-logo">
                            <img src={logo} alt="Jisc Logo" />
                            <Body1Stronger>Cloud Assist</Body1Stronger>
                            <Badge appearance='tint' size='large' color='warning'>ALPHA</Badge>
                        </div>
                        {isSignedIn && <Login></Login>}
                    </header>
                    <Nav collapseNav={collapseNav} onCollapseNav={setCollapseNav} />
                    <div id="content">
                        {(loading || isPending) && <ProgressBar />}
                        {<Routes>
                            <Route path="*" element={<HomePage loading={loading} />} />
                            <Route path="/about/*" element={<About />} />
                            <Route path="/permissions" element={<Permissions />} />
                            <Route path="/consent" element={<Consent />} />
                            {isSignedIn && <>
                                {(tenant?.hasCe || isAdminTenant) && (tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && <>
                                    <Route path="/automations" element={<Tenants />} />
                                    <Route path="/automations/logs" element={<Logs Mode={LogType.Tenant} />} />
                                    <Route path="/database/*" element={<Database />} />
                                    <Route path="/reports/*" element={<Reports />} />
                                </>}
                                <Route path="/health/*" element={<HealthCheck />} />
                            </>}
                        </Routes>}
                    </div>
                </div>
                <Toaster toasterId={toasterId} />
            </JToolContext.Provider>
        </FluentProvider>
    );
}

export default App;