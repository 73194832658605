import {
    Button, makeStyles, Toast, ToastBody, ToastTitle,
    tokens, Tooltip, useToastController
} from "@fluentui/react-components";
import {
    Album20Filled, Album20Regular, ArrowSyncCheckmark20Filled,
    ArrowSyncCheckmark20Regular, bundleIcon, ContentViewGalleryLightning20Filled,
    ContentViewGalleryLightning20Regular, Database20Filled, Database20Regular,
    HeartPulse20Filled, HeartPulse20Regular, Home20Filled, Home20Regular,
    Navigation20Filled, Navigation20Regular, QuestionCircle20Filled,
    QuestionCircle20Regular
} from "@fluentui/react-icons";
import {
    Hamburger, NavCategory, NavCategoryItem, NavDivider, NavDrawer, NavDrawerBody,
    NavDrawerFooter, NavDrawerHeader, NavItem, NavItemValue, NavSubItem,
    NavSubItemGroup
} from "@fluentui/react-nav-preview";
import { startTransition, SyntheticEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useIsSignedIn from "../Hooks/useIsSignedIn";
import { TenantRole } from "../Interfaces";
import JToolContext from "./JToolContext";

interface Props {
    collapseNav: boolean;
    onCollapseNav: (value: boolean) => void;
}

const useStyles = makeStyles({
    hamburger: {
        color: tokens.colorNeutralForeground2,
        textDecorationLine: "none",
        justifySelf: "center",
        alignSelf: "start"
    },
});

function Nav({ collapseNav, onCollapseNav }: Props) {
    const Home32 = bundleIcon(Home20Filled, Home20Regular);
    const ContentView = bundleIcon(ContentViewGalleryLightning20Filled, ContentViewGalleryLightning20Regular);
    const DatabaseIcon = bundleIcon(Database20Filled, Database20Regular);
    const Feed = bundleIcon(ArrowSyncCheckmark20Filled, ArrowSyncCheckmark20Regular);
    const Health = bundleIcon(HeartPulse20Filled, HeartPulse20Regular);
    const ReportIcon = bundleIcon(Album20Filled, Album20Regular);
    const AboutIcon = bundleIcon(QuestionCircle20Filled, QuestionCircle20Regular);
    const NavigationIcon = bundleIcon(Navigation20Filled, Navigation20Regular);
    const styles = useStyles();
    const location = useLocation();
    const navigator = useNavigate();
    const { role, isAdminTenant, loading, isSignedIn } = useIsSignedIn();
    const { tenant, toasterId, jservice } = useContext(JToolContext);
    const [selectedNav, setNav] = useState<string>("home");
    const { dispatchToast } = useToastController(toasterId);
    const [consents, setConsents] = useState<string[] | null>();

    const navItemSelect = (e: Event | SyntheticEvent<Element, Event>, data: { value: NavItemValue, categoryValue?: NavItemValue }) => {
        e.preventDefault();
        setNav(data.value as string);
        if ((data.value.startsWith("automation") || data.value.startsWith("reports")) && !((tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && tenant.hasCe))
            window.open("https://www.jisc.ac.uk/cloud/companion-service", "_blank");
        else startTransition(() => { navigator(`/${(data.value as string) === "home" ? "" : (data.value as string)}`) });
    };

    useEffect(() => startTransition(() =>
        setNav((location.pathname === "/" ? "home" : location.pathname.substring(1)))
    ), [location]);

    useEffect(() => {
        if (tenant && consents === undefined && role === TenantRole.Admin)
            jservice?.tenants().consents({}).then(r => setConsents(r.data.value)).catch(e => {
                dispatchToast(<Toast>
                    <ToastTitle>Error Loading</ToastTitle>
                    <ToastBody>{e.toString()}</ToastBody>
                </Toast>, { intent: "error" });
                console.error(e);
            });
    }, [tenant, consents, dispatchToast, role, jservice]);

    const makeLink = (url: string) =>
        (tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && tenant.hasCe ? url : "https://www.jisc.ac.uk/cloud/companion-service";

    return (<>
        {collapseNav && <Button title="Expand Navigation" style={{ marginTop: 5 }} className={styles.hamburger} onClick={() => onCollapseNav(!collapseNav)} appearance="transparent" icon={<NavigationIcon />} />}
        <NavDrawer style={{ gridArea: "nav" }} onNavItemSelect={navItemSelect} selectedValue={selectedNav}
            type="inline" open={!collapseNav} size="medium" onOpenChange={(_, { open }) => onCollapseNav(!open)}>
            {<NavDrawerHeader>
                <Hamburger title="Collapse Navigation" onClick={() => onCollapseNav(!collapseNav)} />
            </NavDrawerHeader>}
            <NavDrawerBody>
                <Tooltip relationship="label" content="Home">
                    <NavItem role="navigation" href="/" onClick={() => false} value="home" icon={<Home32 />}>
                        Home
                    </NavItem>
                </Tooltip>
                {isSignedIn && !loading && <>
                    <Tooltip relationship="label" content="Automations">
                        <NavItem role="navigation" href={makeLink("/automations")} value="automations" icon={<ContentView />}>
                            365 Automations
                        </NavItem>
                    </Tooltip>
                    {consents?.includes("Device.ReadWrite.All") ? <NavCategory value="reports">
                        <Tooltip relationship="label" content="Reports">
                            <NavCategoryItem role="navigation" value="reports" icon={<ReportIcon />}>
                                365 Reports
                            </NavCategoryItem>
                        </Tooltip>
                        <NavSubItemGroup>
                            <Tooltip relationship="label" content="Device summary">
                                <NavSubItem role="navigation" href="/reports/devicesummary" value="reports/devicesummary">
                                    Device summary
                                </NavSubItem>
                            </Tooltip>
                            <Tooltip relationship="label" content="Device OS summary">
                                <NavSubItem role="navigation" href="/reports/deviceossummary" value="reports/deviceossummary">
                                    Device OS summary
                                </NavSubItem>
                            </Tooltip>
                            <Tooltip relationship="label" content="Out of date devices">
                                <NavSubItem role="navigation" href="/reports/outdated" value="reports/outdated">
                                    Out of date devices
                                </NavSubItem>
                            </Tooltip>
                            <Tooltip relationship="label" content="Stale devices">
                                <NavSubItem role="navigation" href="/reports/stale" value="reports/stale">
                                    Stale devices
                                </NavSubItem>
                            </Tooltip>
                            <Tooltip relationship="label" content="All device export">
                                <NavSubItem role="navigation" href="/reports" value="reports">
                                    All Device export
                                </NavSubItem>
                            </Tooltip>
                        </NavSubItemGroup>
                    </NavCategory> : <Tooltip relationship="label" content="Reports">
                        <NavItem role="navigation" href={makeLink("/reports")} value="Reports" icon={<ReportIcon />}>
                            Reports
                        </NavItem>
                    </Tooltip>}
                    <Tooltip relationship="label" content="Health checks">
                        <NavItem href="/health" value="health" role="navigation" icon={<Health />}>
                            Health Check
                        </NavItem>
                    </Tooltip>
                </>}
            </NavDrawerBody>
            <NavDivider />
            <NavDrawerFooter style={{ padding: "0 var(--spacingHorizontalXS) 0 var(--spacingHorizontalMNudge)" }}>
                {isSignedIn && !loading && tenant && <>
                    {(tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <>
                        {(tenant.hasCe || isAdminTenant) && <>
                            <Tooltip relationship="label" content="Tenant logs">
                                <NavItem role="navigation" href="/automations/logs" value="automations/logs" icon={<Feed />}>
                                    Logs
                                </NavItem>
                            </Tooltip>
                            <NavCategory value="database">
                                <Tooltip relationship="label" content="Database">
                                    <NavCategoryItem icon={<DatabaseIcon />}>
                                        Database
                                    </NavCategoryItem>
                                </Tooltip>
                                <NavSubItemGroup style={{ width: "100%" }}>
                                    <Tooltip relationship="label" content="Feeds">
                                        <NavSubItem role="navigation" href="/database/feeds" value="database/feeds">
                                            Feeds
                                        </NavSubItem>
                                    </Tooltip>
                                    <Tooltip relationship="label" content="Manufacturers & Devices">
                                        <NavSubItem role="navigation" href="/database/manufacturers" value="database/manufacturers">
                                            Manufacturers
                                        </NavSubItem>
                                    </Tooltip>
                                    <Tooltip relationship="label" content="Operating Systems">
                                        <NavSubItem role="navigation" href="/database/os" value="database/os">
                                            Operating Systems
                                        </NavSubItem>
                                    </Tooltip>
                                </NavSubItemGroup>
                            </NavCategory>
                        </>}
                    </>}
                </>}
                <NavCategory value="About">
                    <Tooltip relationship="label" content="About Cloud Assist">
                        <NavCategoryItem icon={<AboutIcon />}>About</NavCategoryItem>
                    </Tooltip>
                    <NavSubItemGroup style={{ width: "100%" }}>
                        <Tooltip relationship="label" content="About Cloud Assist">
                            <NavSubItem role="navigation" href="/about" value="about">
                                About
                            </NavSubItem>
                        </Tooltip>
                        <Tooltip relationship="label" content="Release notes">
                            <NavSubItem role="navigation" href="/about/releasenotes" value="about/releasenotes">
                                Release notes
                            </NavSubItem>
                        </Tooltip>
                        <Tooltip relationship="label" content="Roadmap for up coming features">
                            <NavSubItem role="navigation" href="/about/roadmap" value="about/roadmap">
                                Roadmap
                            </NavSubItem>
                        </Tooltip>
                        <Tooltip relationship="label" content="Terms and Conditions">
                            <NavSubItem role="navigation" href="/about/terms" value="about/terms">
                                Terms and Conditions
                            </NavSubItem>
                        </Tooltip>
                    </NavSubItemGroup>
                </NavCategory>
            </NavDrawerFooter>
        </NavDrawer>
    </>);
}

export default Nav;